import React from 'react';
import { Helmet } from 'react-helmet';

import SiteHeader from '../../../components/SiteHeader/type4';
import SectionHeader from '../../../components/SectionHeader';
import KinetikFeature from '../../../components/KinetikFeature';
import ConsultantFeature from '../../../components/ConsultantFeature';
import Footer from '../../../components/Footer';
import ProjectFeature from '../../../components/ProjectFeature';
import CTA from '../../../components/CTA';
import SiteNavigation from '../../../components/SiteHeader/navigation';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

// @ts-ignore
import MidtkraftLogo from '../../../../static/Logos/midtkraft.svg';

// @ts-ignore
import KinetikNettGif from '../../../../static/KinetikNett/kinetiknett.gif';

// @ts-ignore
import KinetikNettMp4 from '../../../../static/KinetikNett/kinetiknett.mp4';

// @ts-ignore
import KinetikNettMobileMp4 from '../../../../static/KinetikNett/mobile.mp4';

// @ts-ignore
import KinetikNettAlertGif from '../../../../static/KinetikNett/alert.gif';

// @ts-ignore
import KinetikNettAlertMp4 from '../../../../static/KinetikNett/alert.mp4';

import LogoCloud from '../../../components/LogoCloud';

import { AnnotationIcon, GlobeAltIcon, LightningBoltIcon, MailIcon, ScaleIcon, CheckIcon } from '@heroicons/react/outline'
import { StaticImage } from 'gatsby-plugin-image';

const tiers = [
    {
        name: 'Oversikt',
        href: '/om/#kontakt',
        priceMonthly: 3850,
        description: 'Nøkkelinformasjon fra nis/kis systemer i et brukervennlig grensesnitt på alle enheter.',
        features: [
            'Raskt søk på informasjon om punkter eller kunder',
            'Full oversikt over infrastruktur i kart',
            'Nyttige kartlag (kostnadsoversikt, forbruk, lynnedslag med mer)',
            'Mobiltilpasset',
            'Dokumentasjon'
        ],
        required: true,
    },
    {
        name: 'Varsling',
        href: '/om/#kontakt',
        priceMonthly: 1550,
        description: 'Varsle om planlagte eller uforutsette hendelser som oppstår i nettet via flere kanaler. ',
        features: [
            'Enkel og rask varsling i ulike kanaler',
            'Varsling via Facebook, SMS eller nettsider',
            'Muligheter for automatiske varslinger',
        ],
        required: false,
    },
    {
        name: 'Nettops',
        href: '/om/#kontakt',
        priceMonthly: 5750,
        description: 'Integrasjon mot HES som gir mange muligheter til å hente ut og visualisere nyttig informasjon.',
        features: [
            'Visualisering av kortslutningsindikator',
            'Måleverdier i eget kartlag',
            'Alarmer',
            'Adhoc spørringer for ulike rapporter (spenning, nåverdi forbruk, jordfeil med mer)',
        ],
        required: false,
    },
]

const overviewFeatures = [
    {
        id: 1,
        name: 'Relevant data',
        description:
            'Alltid oppdatert informasjon om målere og nettstasjoner. Med lynraskt søk finner du basisinformasjon som målepunktnummer, kundedata, adresser med mer.',
        icon: GlobeAltIcon,
    },
    {
        id: 2,
        name: 'På farten eller på kontoret',
        description:
            'Tjenesten er tilpasset alle skjermstørrelser, og vil fungere like godt på telefonen som på maskinen på kontoret. Mulighet for å aktivere GPS via mobil eller nettbrett gjør det enkelt å finne frem.',
        icon: ScaleIcon,
    },
    {
        id: 3,
        name: 'Nyttig informasjon',
        description:
            <>Vi jobber hele tiden med å legge til informasjon i kartet som kan være nyttig for bruker. I dag finnes muligheter for å visualisere høyt forbruk, høy avbruddskostnad, lynnedslag og farevarsel for ditt&nbsp;område.</>,
        icon: LightningBoltIcon,
    },
]
const alertFeatures = [
    {
        id: 1,
        name: 'Automatisk varsling',
        description:
            'Det finnes muligheter for å aktivere automatisk varsling til kunder dersom det registreres feil i nettet. Varsling sendes til relevante kunder i forhåndsdefinerte kanaler.',
        icon: AnnotationIcon,
    },
    {
        id: 2,
        name: 'Hurtig varsling',
        description:
            'Med et par klikk og mulighet til å velge forhåndsdefinerte maler eller skrive teksten selv, får du varslet kundene med en gang eller planlegge kommende varsel.',
        icon: MailIcon,
    },
    {
        id: 3,
        name: 'Valgfrie kanaler',
        description:
            'Kinetik Nett gjør det mulig å varsle via SMS, Facebook og Nettside. Alt i samme operasjon. Du velger selv hvilken kanal det skal varsles via, enten en eller flere.',
        icon: MailIcon,
    },
]

function KinetikNett({ props }) {
    return (
        <div className="bg-gray-50">
            <Helmet>
                <title>Convivial | Kinetik Nett</title>
                <link rel="canonical" href="https://convivial.no/tjenester/kraft" />
                <meta name="description" content="Utforsk bortgjemte data med Kinetik Nett" />
                <script async defer data-domain="convivial.no" src="https://plausible.io/js/plausible.js"></script>
            </Helmet>
            <main>
                <SiteNavigation bgColor="bg-gray-50" />
                <div className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24 sm:px-6 mb-16">
                    <div className="sm:text-center">
                        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl font-header">
                            <span className="block">Utforsk bortgjemte data&nbsp;med</span>{' '}
                            <span className="block text-kinetik">Kinetik Nett</span>
                        </h1>
                        <p className="mt-3 max-w-md sm:mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                            Med Kinetik Nett får nettselskapene tilgang til bortgjemte data via et brukervennlig og moderne grensesnitt.
                        </p>
                    </div>
                </div>

                <div className="bg-gray-50">
                    <div className="relative overflow-hidden">
                        <div className="relative">
                            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
                                <div className="flex-1" />
                                <div className="flex-1 w-full bg-white" />
                            </div>
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 pb-6">
                                <video src={KinetikNettMp4} controls={false} muted autoPlay loop={true} className="relative rounded-lg w-full shadow-lg">
                                    Video not supported in your browser
                                </video>
                            </div>
                        </div>
                    </div>
                    <LogoCloud bgColor="bg-white" product={['kinetiknett']} />
                </div>

                <div className="py-16 bg-gray-50 overflow-hidden lg:py-24">
                    <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                        <div className="relative">
                            <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                                Skreddersydd for&nbsp;nettselskaper
                            </h2>
                            <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">
                                I tett samarbeid med ressurspersoner og entusiaster i flere nettselskaper har vi laget et verktøy som gjør hverdagen deres enklere.
                                Dette har vi pakket inn i et grensesnitt det er enkelt å bruke for alle.
                            </p>
                        </div>

                        <div className="relative mt-12 lg:mt-24 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                            <div className="relative">
                                <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                                    Full oversikt
                                </h3>
                                <p className="mt-3 text-lg text-gray-500">
                                    Kinetik Nett visualiserer data fra ulike bakenforliggende systemer i et brukervennlig grensesnitt som gjør det enkelt og raskt for brukere å få oversikt.
                                </p>

                                <dl className="mt-10 space-y-10">
                                    {overviewFeatures.map((item) => (
                                        <div key={item.id} className="relative">
                                            <dt>
                                                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-kinetik text-white">
                                                    <item.icon className="h-6 w-6" aria-hidden="true" />
                                                </div>
                                                <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                            </dt>
                                            <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                        </div>
                                    ))}
                                </dl>
                            </div>

                            <div className="mt-10 -mx-4 relative lg:mt-0" aria-hidden="true">
                                <video src={KinetikNettMobileMp4} width={490} controls={false} muted autoPlay loop={true} className="relative mx-auto rounded-lg shadow-2xl">
                                    Video not supported in your browser
                                </video>
                            </div>
                        </div>

                        <div className="relative mt-12 sm:mt-16 lg:mt-24">
                            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                                <div className="lg:col-start-2">
                                    <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Utrolig smidig varsling</h3>
                                    <p className="mt-3 text-lg text-gray-500">
                                        Har du behov for å varsle kunder om utfall eller planlagte utkoblinger? Med Kinetik Nett har vi gjort varsling til en lek, og du varsler enkelt kunder i flere kanaler i en og samme operasjon.
                                    </p>

                                    <dl className="mt-10 space-y-10">
                                        {alertFeatures.map((item) => (
                                            <div key={item.id} className="relative">
                                                <dt>
                                                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-kinetik text-white">
                                                        <item.icon className="h-6 w-6" aria-hidden="true" />
                                                    </div>
                                                    <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                                </dt>
                                                <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                            </div>
                                        ))}
                                    </dl>
                                </div>

                                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                                    <video src={KinetikNettAlertMp4} width={364} controls={false} muted autoPlay loop={true} className="relative mx-auto rounded-lg shadow-2xl">
                                        Video not supported in your browser
                                    </video>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <section className="bg-gray-50 overflow-hidden pb-20">
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">

                        <div className="relative">
                            <img
                                className="mx-auto h-8"
                                src={MidtkraftLogo}
                                alt="Workcation"
                            />
                            <blockquote className="mt-10">
                                <div className="max-w-3xl mx-auto text-center text-2xl leading-9 font-medium text-gray-900">
                                    <p>
                                        &ldquo;Brukervennlig og effektiv kart-løsning som gir Midtkraft Nett god oversikt i forsyningsområdet.
                                        Kinetik Nett gir oss muligheten for effektiv varsling av kunder, samt redusert responstid ved feil.&rdquo;
                                    </p>
                                </div>
                                <footer className="mt-8">
                                    <div className="md:flex md:items-center md:justify-center">
                                        <div className="md:flex-shrink-0">
                                            <StaticImage className="mx-auto h-10 w-10 rounded-full" src="../../../../static/KinetikNett/JensKristian.jpg" alt="Jens Kristian Støa" />
                                        </div>
                                        <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                                            <div className="text-base font-medium text-gray-900">Jens Kristian Støa</div>

                                            <svg className="hidden md:block mx-1 h-5 w-5 text-kinetik-dark" fill="currentColor" viewBox="0 0 20 20">
                                                <path d="M11 0h3L9 20H6l5-20z" />
                                            </svg>

                                            <div className="text-base font-medium text-gray-500">Midtkraft Nett</div>
                                        </div>
                                    </div>
                                </footer>
                            </blockquote>
                        </div>
                    </div>
                </section>

                <div className="bg-kinetik-dark">
                    <div className="pt-12 sm:pt-16 lg:pt-24">
                        <div className="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                            <div className="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                                <h2 className="text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">PRISER</h2>
                                <p className="text-3xl font-extrabold text-white sm:text-4xl lg:text-5xl">
                                    Ingen skjulte&nbsp;priser
                                </p>
                                <p className="text-xl text-gray-300">
                                    Skjulte priser eller pris på forespørsel er utrolig irriterende. Derfor ønsker vi å synliggjøre de faste kostnadene for modulene.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="mt-8 bg-gray-50 sm:mt-12 lg:mt-16">
                        <div className="relative">
                            <div className="absolute inset-0 h-3/4 bg-kinetik-dark" />
                            <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                                <div className="max-w-md mx-auto space-y-4 lg:max-w-5xl lg:grid lg:grid-cols-3 lg:gap-5 lg:space-y-0 ">
                                    {tiers.map((tier) => (
                                        <div key={tier.name} className="flex flex-col rounded-lg shadow-lg overflow-hidden">
                                            <div className="px-6 py-8 bg-white sm:p-10 sm:pb-6">
                                                <div>
                                                    <h3
                                                        className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-kinetik text-kinetik-dark"
                                                        id="tier-standard"
                                                    >
                                                        {tier.name}
                                                    </h3>
                                                    {tier.required ? " * påkrevd" : null}
                                                </div>
                                                <div className="mt-4 flex items-baseline text-6xl font-extrabold">
                                                    {tier.priceMonthly}
                                                    <span className="ml-1 text-2xl font-medium text-gray-500">kr/mnd</span>
                                                </div>
                                                <p className="mt-5 text-lg text-gray-500">{tier.description}</p>
                                            </div>
                                            <div className="flex-1 flex flex-col justify-between px-6 pt-6 pb-8 bg-gray-50 space-y-6 sm:p-10 sm:pt-6">
                                                <ul className="space-y-4">
                                                    {tier.features.map((feature) => (
                                                        <li key={feature} className="flex items-start">
                                                            <div className="flex-shrink-0">
                                                                <CheckIcon className="h-6 w-6 text-green-500" aria-hidden="true" />
                                                            </div>
                                                            <p className="ml-3 text-base text-gray-700">{feature}</p>
                                                        </li>
                                                    ))}
                                                </ul>
                                                <div className="rounded-md shadow">
                                                    <a
                                                        href={tier.href}
                                                        className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gray-800 hover:bg-gray-900"
                                                        aria-describedby="tier-standard"
                                                    >
                                                        Ta kontakt
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
                            <div className="max-w-md mx-auto lg:max-w-5xl">
                                <div className="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
                                    <div className="flex-1">
                                        <div>
                                            <h3 className="inline-flex px-4 py-1 rounded-full text-sm font-semibold tracking-wide uppercase bg-white text-gray-800">
                                                Alt sammen
                                            </h3>
                                        </div>
                                        <div className="mt-4 text-lg text-gray-600">
                                            Ved kjøp av alle moduler får du naturligvis en rabattert pris. <span className="font-semibold text-2xl text-gray-900">10150 kr/mnd</span>
                                            <div className="text-sm mt-2 text-gray-400">* Oppstart og implementering vil tilkomme som et engangsbeløp.</div>
                                            <div className="text-sm text-gray-400">* Alle priser er oppgitt eks.mva. Og forutsetter en 3 års avtale. </div>
                                            <div className="text-sm text-gray-400">Kinetik Nett leveres som SaaS, det betyr at drift og vedlikehold er inkludert i prisen</div>
                                        </div>
                                    </div>
                                    <div className="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
                                        <a
                                            href="/om/#kontakt"
                                            className="flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-900 bg-white hover:bg-gray-50"
                                        >
                                            Ta kontakt
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <CTA
                    demo={true}
                    demoLink="https://outlook.office365.com/owa/calendar/kinetik1@convivial.no/bookings/s/EMLEb5w33EOXJWljOkL6-w2"
                    header="Fortsatt nysgjerrig?"
                    subHeader="Book en demo eller kontakt&nbsp;oss."
                    bgColor="bg-gray-50"
                    contactButtonColor="bg-kinetik"
                    demoButtonColor="text-kinetik"
                />

            </main>
            <Footer />
        </div>
    );
}

export default KinetikNett;
